<template>
  <div class="pageBox">
    <div class="content">
      <h3 class="title">
        <p class>新的认证</p>
        <svg class="msgIcon" aria-hidden="true" @click="lookStep(false)">
          <use xlink:href="#iconwenhaoxiao" />
        </svg> 
      </h3>
      <div class="applicationBox">
        <el-form
          class="formBox"
          :model="form"
          :rules="rules"
          ref="form"
          label-width="auto"
          @validate="formValidate"
        >
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="应用名称" prop="apkName">
                <el-input
                  v-inputInserted="'apkName'"
                  aria-label="应用名称"
                  aria-autocomplete="none"
                  clearable
                  placeholder="请输入应用名称"
                  type="text"
                  v-model.trim="form.apkName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="应用包名" prop="apkPackage">
                <el-input
                  v-inputInserted="'apkPackage'"
                  aria-label="应用包名"
                  aria-autocomplete="none"
                  clearable
                  placeholder="请输入应用包名"
                  type="text"
                  v-model.trim="form.apkPackage"
                  :disabled="$route.query.apkPackage && $route.query.apkPackage!==''"
                ></el-input>
                <div class="msg">您申请认证的应用包名，提交后不可修改</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="versionCodeItem">
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="版本号" prop="versionCode">
                <el-input
                  v-inputInserted="'versionCode'"
                  aria-label="版本号"
                  aria-autocomplete="none"
                  clearable
                  placeholder="请输入版本号"
                  type="text"
                  v-model.trim="form.versionCode"
                ></el-input>
                <div class="msg">示例：如应用的版本名称（versionname）为1.23.4.0，版本号(versioncode)为2345，此处需要填写版本号2345</div>
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item label="ICON" prop="apkIcon" class="apkIconBox">
                <el-upload
                  v-inputInserted="'apkIcon'"
                  class="uploaderFile"
                  accept="image/png, image/PNG"
                  :action="configBaseURL+'/api/upload'"
                  :data="{type:'identifyIcon'}"
                  :show-file-list="false"
                  :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'apkIcon')}"
                  :before-upload="(res)=>{return beforeUpload(res,2)}"
                >
                  <img v-if="form.apkIcon" class="file apkIcon" :src="form.apkIcon" />
                  <i v-else class="el-icon-plus icon apkIcon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item
                label="应用信息"
                prop="fileName"
                style="width:0;height:0;opacity:0;overflow:hidden;"
              >
                <el-input v-inputInserted="'fileName'" v-model="form.fileName" disabled></el-input>
              </el-form-item>
              <el-form-item label="应用信息" prop="fileUrl">
                <div class="apkFormFileBox">
                  <div class="apkFormFile">
                    <a class="href" v-if="form.fileUrl" :href="form.fileUrl" target="_blank">{{form.fileName}}</a>
                  </div>
                  <el-upload
                    accept=".PDF, .pdf"
                    v-inputInserted="'fileUrl'"
                    class="uploaderFile"
                    :action="configBaseURL+'/api/upload'"
                    :data="{type:'identifyReview'}"
                    :show-file-list="false"
                    :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'fileUrl')}"
                    :before-upload="(res)=>{return beforeUpload(res,10)}"
                  >
                    <div class="apkFormBtn">上传附件</div>
                  </el-upload>
                </div>
                <div class="msg">
                  请下载
                  <a
                    class="blue"
                    href="https://statres.itgsa.com/static/ITGSA联盟开发者金标认证申请填报材料.docx"
                    download
                  >
                    《
                    <span class="defaultHref">应用信息模板</span>》
                  </a> ，填写内容后打印文件并 <b class="black">加盖公章</b>，扫描为PDF格式后 <b class="black">上传PDF文件</b>
                </div>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
              <el-form-item>
                <router-link
                  class="w200 whiteBtn margin-r30"
                  to="/console/goldIdentification/list"
                >取消</router-link>
                <el-button
                  class="w200 bgBlack white"
                  :class="{btnDisabled:Object.keys(formValid).length != Object.keys(rules).length}"
                  @click="Object.keys(formValid).length == Object.keys(rules).length && submitForm('form')"
                >提交认证</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="msgBox">
          <p class="msg">提交认证前，请确保您已在OPPO、vivo、小米三家的软件商店发布了最新版本，且版本号一致。</p>
          <p class="msg">目前暂不支持三家商店中包名不一致的应用。</p>
        </div>
      </div>
    </div>
    <custom-dialog ref="customDialog">
      <div class="messageContent">
        <div class="error">{{authenticationErroe}}</div>
        <div class="membersBox">
          <span class="msg">快速访问：</span>
          <a class="member" href="https://dev.mi.com/console/app/newapp.html" target="_blank">小米开放平台</a>
          <a class="member" href="https://open.oppomobile.com" target="_blank">OPPO开放平台</a>
          <a class="member" href="https://dev.vivo.com.cn/home" target="_blank">vivo开放平台</a>
        </div>
        <div class="confirm" @click="$refs.customDialog.open(false)">知道了</div>
      </div>
    </custom-dialog>
    <step v-if="!isLookStep" :stepSwitch="!isLookStep" @close="lookStep"></step>
  </div>
</template>
<script>
import step from "./template/step"; //认证步骤模板
import customDialog from "@/components/customDialog";
import { singleGoldIdentificationSever } from "@/common/api-config";
export default {
  components: {
    customDialog,
    step,
  },
  created() {
    if (this.$route.query.apkPackage) {
      //是否有金标应用ID
      this.queryIdentifyDetail(this.$route.query.apkPackage); //获取金标应用详情
    }
  },
  data() {
    var checkApkPackage = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写应用包名"));
      } else {
        let reg = /[\u4E00-\u9FA5]/g;
        if (reg.test(value)) {
          callback(new Error("应用包不可输入中文"));
        } else {
          callback();
        }
      }
    };
    var checkVersionCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写版本号"));
      } else {
        let reg = /^(0+)|[^\d]+/g;
        if (reg.test(value)) {
          callback(new Error("请填写申请认证的版本号，注意是不带小数点的版本号（versioncode）"));
        } else {
          callback();
        }
      }
    };
    return {
      isLookStep: localStorage.getItem("isLookStep"), //存缓存,
      authenticationErroe: "", //提交认证错误信息
      form: {
        apkName: "", //应用名称
        apkPackage: "", //应用包名
        versionCode: null, //版本号code
        apkIcon: "", //图标
        // fileName: "", //应用信息表资源地址
        // fileUrl: "", ////应用信息表名称
        test:"test"
      },
      formValid: {}, //用于检验form是否通过
      rules: {
        apkName: [
          { required: true, message: "请填写应用名称", trigger: "blur" },
          { max: 15, message: '最大限制15个字', trigger: 'blur' }
        ],
        apkPackage: [
          { required: true, message: "请填写应用包名", trigger: "blur" },
          { max: 100, message: '最大限制100', trigger: 'blur' },
          { validator: checkApkPackage, trigger: "blur" },
        ],
        versionCode: [
          { required: true, message: "请填写版本号", trigger: "blur" },
          // { type: "number", message: "请填写申请认证的版本号，注意是不带小数点的版本号（versioncode）。" },
          { validator: checkVersionCode, trigger: "blur" },
        ],
        apkIcon: [{ required: true, message: "请上传Icon", trigger: "blur" }],
        // fileName: [
        //   { required: true, message: "请上传应用信息", trigger: "blur" },
        // ],
        // fileUrl: [
        //   { required: true, message: "请上传应用信息", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    //认证步骤关闭
    lookStep(data) {
      this.isLookStep = data;
      localStorage.setItem("isLookStep", data);

    },
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
    //上传前校验
    beforeUpload(file, size) {
      const isSize = file.size / 1024 / 1024 < size;
      if (!isSize) {
        this.$message.error(`上传文件大小不能超过 ${size}MB!`);
      }
      return isSize;
    },
    //上传成功
    handleUpload(res, file, filelist, key) {
      if (res.code == 0) {
        if (key == "apkIcon") {
          this.form[key] = res.data.link;
        }
        if (key == "fileUrl") {
          this.form.fileName = res.data.name;
          this.form.fileUrl = res.data.link;
        }
      } else {
        this.$message.error(res.message);
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs.customDialog.open();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          singleGoldIdentificationSever.identifyApply(this.form).then((res) => {
            if (res.code == 0) {
              this.$msgBox
                .alertMessageBox({
                  title: "认证提交成功",
                  content: "请耐心等待官方认证，我们将会通过邮件通知您认证结果",
                })
                .then(async (data) => {
                  this.$router.push("/console/goldIdentification/list");
                })
                .catch((val) => {});
            } else {
              this.authenticationErroe = res.message;
              this.$refs.customDialog.open(true);
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取应用详情
    queryIdentifyDetail(apkPackage) {
      singleGoldIdentificationSever
        .queryIdentifyLastDetail({
          apkPackage: apkPackage,
        })
        .then((res) => {
          if (res.code == 0) {
            this.form = Object.assign(this.form, utils.deepClone(res.data));
          } else {
            this.$message.error("提交失败");
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content {
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px;
      font-weight: bold;
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(../../../assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }
      .msgIcon {
        margin: 0 64px 0 12px;
        width: 18px;
        height: 18px;
        cursor: pointer;
         &:hover{
          transform:scale(1.5);
          transform:scale(1.5);		
				}
      }
    }
    .applicationBox {
      padding: 70px 50px;
      .formBox {
        margin: auto;
        width: 875px;
        /deep/ .el-form-item__label {
          color: #999 !important;
        }
        .apkIconBox {
          /deep/ .el-upload {
            border: none !important;
          }
          .apkIcon {
            width: 120px;
            height: 120px;
            line-height: 120px;
            text-align: center;
            background: rgba(153, 153, 153, 1);
            &:before {
              content: "\e6d9";
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        .apkFormFileBox {
          display: flex;
          .apkFormFile {
            margin-right: 9px;
            width: 351px;
            height: 40px;
            padding-left: 15px;
            border: 1px solid rgba(227, 227, 227, 1);
              overflow:hidden;
              text-overflow:ellipsis;
              -o-text-overflow:ellipsis;
              -webkit-text-overflow:ellipsis;
              -moz-text-overflow:ellipsis;
              white-space:nowrap;
            }    
          /deep/ .el-upload {
            border: none !important;
            border-radius: 0;
          }
          .apkFormBtn {
            position: relative;
            width: 121px;
            height: 40px;
            background: rgba(33, 33, 37, 1);
            font-size: 14px;
            color: #fff;
            line-height: 40px;
            text-align: center;
            .formFile {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .versionCodeItem{
          margin-bottom: 10px;
        }
        .msg {
          color: #999;
          line-height: 26px;
        }
        .btnDisabled {
          opacity: 0.25;
          cursor: not-allowed;
        }
      }
    }
    .msgBox {
      width: 630px;
      margin: auto;
      .msg {
        position: relative;
        height: 30px;
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
        line-height: 30px;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -10px;
          width: 5px;
          height: 5px;
          transform: translateY(-50%);
          border-radius: 50%;
          background: #999;
        }
      }
    }
  }

  .messageContent {
    padding: 0 50px;
    .error {
      margin: 60px 0 34px 0;
      font-size: 16px;
      color: #666;
    }
    .membersBox {
      font-size: 16px;
      .msg {
        color: #666;
        font-weight: bold;
      }
      .member {
        margin-left: 10px;
        color: #999;
        text-decoration: underline;
        &:hover {
          color: #ded094;
        }
      }
    }
    .confirm {
      margin: 50px auto 30px;
      width: 331px;
      height: 50px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(0, 0, 0, 1);
      border-radius: 5px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      color: #000;
      cursor: pointer;
      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}
</style>

