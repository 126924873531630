<template>
  <el-dialog
    :visible.sync="dialogSwitch"
    :close-on-click-modal="false"
    class="dialogBox"
    width="734px"
    :show-close="false"
    :modal-append-to-body="false"
  >
    <svg class="closeIcon" aria-hidden="true" @click="dialogSwitch = false">
      <use xlink:href="#iconguanbi" />
    </svg>
    <h3 class="messageTitle">认证提交失败</h3>
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      dialogSwitch: false,
    };
  },
  methods: {
    open(dialogSwitch) {
      this.dialogSwitch = dialogSwitch;
    },
  },
};
</script>

<style scoped lang="scss">
.dialogBox {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
  .closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
  }
  .messageTitle {
    position: relative;
    font-size: 18px;
    color: rgba(18, 18, 18, 1);
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 73px;
      height: 6px;
      background: rgba(222, 208, 148, 1);
      border-radius: 3px;
    }
  }
}
</style>
